import Head from "next/head";
import Custom404View from "@/kyna/view/_public/404View";


export default function Custom404() {
  return (
    <div>
      <Head><title>404 - Page Not Found</title> </Head>
      <Custom404View />
    </div>
        
  
  )
}
